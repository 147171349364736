<template>
  <main class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />

    <div class="box">
      <div class="no-border-box">
        <button
          class="btn download-box"
          style="background: #e9e7e7; color: #000000"
          @click="goPrevious"
        >
          <img
            src="@/assets/img/arrow-left-circle-fill.svg"
            alt="delete"
            srcset=""
          />
          Previous Page
        </button>
      </div>
    </div>

    <hr style="border: 1px solid #e5e5e5" />

    <div class="page__head__actions">
      <div class="d-flex w-100 justify-content-end">
        <div class="align-self-center mx-2 dropdown">
          <button
            class="button button--grey text--capital text--small d-flex align-items-center"
            type="button"
            @click="toggleFilterModal"
          >
            <span class="filter_text"> Filter </span>
            <span class="dropdown__icon">
              <ion-icon name="caret-down-outline"></ion-icon>
            </span>
          </button>
        </div>

        <div class="align-self-center mx-2 dropdown">
          <button
            class="button button--grey text--capital text--small d-flex align-items-center"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            export
            <span class="dropdown__icon">
              <ion-icon name="caret-down-outline"></ion-icon>
            </span>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <download-excel
              class="dropdown-item text--capital"
              style="cursor: pointer"
              :data="download"
              :fields="json_fields"
              v-if="userCanExportToPDF"
              type="csv"
              name="sales_details.csv"
            >
              CSV
            </download-excel>
          </div>
        </div>
      </div>
    </div>

    <hr style="border: 1px solid #e5e5e5" />

    <div style="display: flex; width: 50%; font-size: 14px" v-if="filterText">
      <div style="width: 80%">
        {{ filterText }}
      </div>
      <div style="display: flex; width: 10%">
        <span style="cursor: pointer; font-size: 17px" @click="editFilter">
          <i style="padding-right: 5px" class="bi bi-pencil-square"></i>
        </span>
        <span style="cursor: pointer; font-size: 17px" @click="deleteFilter">
          <i style="padding-left: 5px" class="bi bi-x-circle-fill"></i>
        </span>
      </div>
    </div>

    <div class="page__body">
      <div class="mt5 w-100" style="overflow-x: scroll">
        <table class="customtable _customtable">
          <thead>
            <tr>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                product
              </th>
              <!-- <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="font-size: 12px"
              >
                sector
              </th> -->
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                id
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                holder name
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                amount
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                state
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                loan officer
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                branch
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                pre liquidated
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                refinanced
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                created on
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                disbursement date
              </th>
            </tr>
          </thead>
          <tbody v-if="isLoading">
            <tr>
              <td colspan="12">
                <div
                  class="d-flex align-items-center justify-content-center p5"
                >
                  <div
                    class="spinner-border"
                    style="width: 3rem; height: 3rem"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span
                    class="text--black text--600 text--medium d-inline-flex ml-4"
                    >Please wait</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
          <tbody
            v-else-if="
              salesDetailsData.data &&
              salesDetailsData.data.length &&
              !isLoading
            "
          >
            <tr v-for="(success, i) in salesDetailsData.data" :key="i">
              <td style="padding-right: 40px !important">
                <span
                  class="table__body__text table__text"
                  style="word-break: break-word; padding-right: 40px"
                >
                  {{ success.loanProduct }}
                </span>
              </td>

              <!-- <td style="font-size: 12px">
                <span
                  class="table__body__text table__text text--capital"
                  style="font-size: 12px"
                >
                  {{ "" }}
                </span>
              </td> -->

              <td>
                <span class="table__body__text table__text text--capital">
                  {{ success.loanId }}
                </span>
              </td>

              <td>
                <span class="table__body__text table__text text--capital">
                  {{ success.accountHolderName }}
                </span>
              </td>

              <td>
                <span class="table__body__text table__text text--capital">
                  {{ success.amount | currency }}
                </span>
              </td>

              <td>
                <span class="table__body__text table__text">
                  {{ success.accountState }}
                </span>
              </td>

              <td>
                <span class="table__body__text table__text">
                  {{ success.loanOfficer }}
                </span>
              </td>

              <td>
                <span class="table__body__text table__text">
                  {{ success.customerBranch }}
                </span>
              </td>

              <td>
                <span
                  class="table__body__text table__text text-success"
                  v-if="success.wasPreLiquidated"
                >
                  True
                </span>
                <span class="table__body__text table__text text-danger" v-else>
                  False
                </span>
              </td>

              <td>
                <span
                  class="table__body__text table__text text-success"
                  v-if="success.wasFinanceced"
                >
                  True
                </span>
                <span class="table__body__text table__text text-danger" v-else>
                  False
                </span>
              </td>

              <td>
                <span class="table__body__text table__text">
                  {{ success.loanCreationDate | moment }}
                </span>
              </td>

              <td>
                <span class="table__body__text table__text">
                  {{ "" }}
                </span>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="12">
                <div
                  class="d-flex flex-column align-items-center justify-content-center p5"
                >
                  <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                  <span
                    class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >
                    No data available</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <Pagination
          v-if="!isLoading && salesDetailsData.data"
          :allItems="salesDetailsData"
          :size="size"
          @update-created-task="changePageSize"
          @change-page-index="stepPage"
        />
      </div>
    </div>

    <!-- Filter -->
    <CreateFilter
      :show="showFilterModal"
      @close="toggleFilterModal"
      @send-activate-filter="sendFilter"
      @reload="reloadFilter"
      :filterEditValue="filterEdit"
      :showEditedValue="showEditedValue"
      @alertError="alertError"
      :filterHeaderName="'Sales Report'"
      filterCategory="sales_report"
    />

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
// import { mapState } from "vuex";
import moment from "moment";
import ApiService from "@/core/services/general.service";
import JsonExcel from "vue-json-excel";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";

export default {
  name: "SalesReportDetails",
  props: ["id", "startDate", "endDate"],
  data() {
    return {
      content_header: {
        title: "Sales Report Details",
        subTitle: `Break down of sales report items`,
      },
      salesDetailsData: [],
      isLoading: false,
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      size: 20,
      pageIndex: 1,
      json_fields: {
        "Loan Product": "loanProduct",
        // "Loan Product Sector (Public or Private)": "sector",
        "Loan Account ID": "loanId",
        "Account Holder Name": "accountHolderName",
        Amount: "amount",
        "Loan Account State": "accountState",
        "Loan Officer (Loan)": "loanOfficer",
        "Branch (Customer)": "customerBranch",
        "Was Pre-Liquidated": "wasPreLiquidated",
        "Was Refinanced (Topup)": "wasFinanceced",
        "Loan Creation Date": "loanCreationDate",
        "Loan Disbursement Date": "disbursement",
      },
      exportData: [],
      filterText: "",
      showFilterModal: false,
      filterEdit: [],
      showEditedValue: 0,
      fetchAccount: {
        filters: [],
        isFromFilter: false,
        filterCategory: "",
      },
    };
  },

  components: {
    downloadExcel: JsonExcel,
    Pagination: () =>
      import(
        /* WebpackChunkName: "Pagination" */ "@/components/UI/pagination.vue"
      ),
    CreateFilter: () =>
      import(
        /* WebpackChunkName: "CreateFilter" */ "@/view/pages/deposit/filter.vue"
      ),
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    download() {
      let data = [];
      if (this.exportData.length) {
        this.exportData.map((item) => {
          let new_obj = { ...item };
          new_obj.loanCreationDate = moment(item.loanCreationDate).format(
            "DD-MM-YYYY"
          );
          // new_obj.sector = "";
          new_obj.disbursement = "";

          data.push(new_obj);
        });
      }

      return data;
    },
    userCanExportToPDF() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanDownloadDocument;
    },
  },

  methods: {
    goPrevious() {
      this.$router.go(-1);
    },

    async getSalesReportDetails() {
      try {
        this.isLoading = true;
        const data = {
          paramerterId: this.id,
          startDate: this.startDate,
          endDate: this.endDate,
          pageIndex: this.pageIndex,
          pageSize: this.size,
          filters: this.fetchAccount.filters,
        };
        const res = await ApiService.post(
          `SalesReport/get-sales-report-details`,
          data
        );
        this.salesDetailsData = res.data.data;
        this.filterText = res.data.filterText
          ? res.data.filterText.replace(/_/g, " ")
          : "";
        this.filterEdit = res.data.filters ? res.data.filters : [];
        // this.exportData = res.data.data.data;
        if (this.fetchAccount.isFromFilter) {
          this.getExportData();
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },

    sendFilter(value) {
      const filterArray = value.map((obj) => {
        const payload = {};

        payload.filterField = obj.filterName;
        payload.operation = obj.filterOp;
        if (obj.value1 === "" || obj.value1 === null) {
          payload.fieldValues = [obj.value];
          return payload;
        }
        payload.fieldValues = [obj.value, obj.value1];
        return payload;
      });
      this.fetchAccount.filters = filterArray;
      this.fetchAccount.filterCategory = "sales_report";
      this.fetchAccount.isFromFilter = true;
      this.getSalesReportDetails();
    },

    reloadFilter() {
      this.deleteFilter();
      this.fetchAccount.isFromFilter = false;
      this.fetchAccount.filterCategory = "";
      this.fetchAccount.filters = [];
      this.size = 20;
      this.pageIndex = 1;
      this.getSalesReportDetails();
    },

    getExportData() {
      if (this.salesDetailsData.totalCount > 0) {
        const data = {
          paramerterId: this.id,
          startDate: this.startDate,
          endDate: this.endDate,
          pageIndex: 1,
          pageSize: this.salesDetailsData.totalCount,
          filters: this.fetchAccount.filters,
        };
        ApiService.post(
          `SalesReport/get-sales-report-details`,
          data
        ).then((res) => {
          this.exportData = res.data.data;
        });
      }
    },

    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },

    deleteFilter() {
      this.filterText = "";
      this.filterEdit = [];
    },

    editFilter() {
      this.showEditedValue = Math.random();
      this.toggleFilterModal();
    },

    // Alerts
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      this.alert.array = null;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertError(description, array = null) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      this.alert.array = array;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      this.alert.array = null;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    // Paging
    changePageSize(pageSize) {
      this.size = pageSize;
      this.getSalesReportDetails();
    },

    stepPage(direction = null, pageSize) {
      this.size = pageSize;

      if (direction == "next") {
        this.pageIndex = this.salesDetailsData.currentPage + 1;
        this.getSalesReportDetails();
      } else if (direction == "previous") {
        this.pageIndex = this.salesDetailsData.currentPage - 1;
        this.getSalesReportDetails();
      } else if (direction != null) {
        this.pageIndex = direction;
        this.getSalesReportDetails();
      }
    },
  },

  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },

  mounted() {
    this.getSalesReportDetails();
  },
};
</script>
<style lang="scss" scoped>
.page__tabs1 {
  grid-template-columns: repeat(5, 1fr) !important;
}
._customtable {
  width: 100%;
}

._customtable thead th:first-of-type {
  width: 190px;
}
.no-border-box {
  width: 82%;
  // border: 1px solid #C4C4C4;
  // box-sizing: border-box;
  // border-radius: 7px;
  height: 45px;
}
.box {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.download-box {
  width: 185px;
  height: 45px;
  background: #000000;
  border-radius: 5px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #ffffff;
}
</style>
